<template>
  <div class="nuxt-layout-wrapper">
    <div class="layout-header-wrapper">
      <AppHeader></AppHeader>
    </div>
    <div class="layout-main-wrapper">
      <NuxtPage />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.nuxt-layout-wrapper {
  min-height: 100vh;
  width: 100%;
  min-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .layout-header-wrapper {
    width: 100%;
    flex-shrink: 0;
  }
  .layout-main-wrapper {
    width: 100%;
    flex: 1;
    height: auto;
    position: relative;
  }
}
</style>
